import React from 'react';

import { CountryCodes, MobileAppPlatform } from 'src/common/enums';

type DownloadAndroidAppButtonProps = {
  platform: MobileAppPlatform;
  language: string;
  country: CountryCodes;
  href: string;
  className?: string;
};

export const getAppAsset = (platform: MobileAppPlatform, language: string) => {
  let locale = 'en';
  if (language === 'vi') {
    locale = 'vn';
  } else if (language === 'id') {
    locale = 'id';
  }
  if (platform === MobileAppPlatform.IOS) {
    return {
      src: `images/mobile/apple-store-badge-${locale}.png`,
      alt: 'Download on the App Store',
    };
  }
  return {
    src: `images/mobile/google-play-badge-${locale}.png`,
    alt: 'Get it on Google Play',
  };
};

const getAssetWidth = (country: CountryCodes) => {
  if (country === CountryCodes.ID) {
    return 180;
  }
  return 150;
};

const DownloadMobileAppButton = ({
  platform,
  language,
  country,
  href,
  className,
}: DownloadAndroidAppButtonProps) => {
  return (
    <a href={href} rel="noreferrer" target="_blank" className={className}>
      <img
        {...getAppAsset(platform, language)}
        width={getAssetWidth(country)}
        height={'auto'}
        loading="lazy"
      />
    </a>
  );
};

export default DownloadMobileAppButton;
